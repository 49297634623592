html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #A1A1A1;
  border: 0 none #FFFFFF;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9C9C9C;
}

::-webkit-scrollbar-thumb:active {
  background: #7A7A7A;
}

::-webkit-scrollbar-track {
  background: #FFFFFF;
  border: 0 none #FFFFFF;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #FFFFFF;
}

::-webkit-scrollbar-track:active {
  background: #D9D9D9;
}

::-webkit-scrollbar-corner {
  background: transparent;
}



