.container-home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & svg {
    font-size: 40px;
  }
}

.img-logo-text {
  max-width: 40vw;
  max-height: 50vh;
}

.direct-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;


  width: 120px;
  height: 120px;
  background: #f3f0f1;
  margin-bottom: 25px;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
  color: gray;

  transition: all 0.1s ease-in-out;

  span {
    font-size: 16px;
  }

  &:nth-child(1) {
    box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
    6px 6px 10px rgba(0, 0, 0, 0.2);

    &:active {
      opacity: 1;
      box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 16px rgba(0, 0, 0, 0.1);
    }
  }
}

