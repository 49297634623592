.temp-text {
  color: #ffc800;
  font-size: 20px;
}

.makeStyles-drawerClose-18 {
  width: 57px;
}

.MuiToolbar-gutters {
  padding-left: 16px;
  padding-right: 16px;
}

.hide-item {
  display: none !important;
}

.MuiToolbar-gutters {
  padding: 0 !important;
}

.img-logo {
  height: 50px;
}

.main-class {
  height: 100%;

  & .component-container {
    padding: 0 8px 0 8px;
    overflow: auto;
    position: relative;
    height: calc(100% - 65px);
    text-align: justify;
  }
}

.nav-item-active > div:first-child {
  border-right: solid 4px #ffc107;
  background-color: #ededed;
}

button.avatar-user {
  border-radius: 8px;
  padding: 0 8px;
}