.App-header {
  text-align: center;
}

html,
body,
#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}

.pos-rel {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.col-gray {
  color: gray;
}
