.mainImage {
  object-fit: cover;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 4px);
  object-position: left;
}

.logo {
  height: 150px;
  filter: drop-shadow(0 0 10px white);
}



.main-img-logo {
  position: absolute;
  right: 10px;
}

/*.crd-main {
  background-color: #febf00 !important;
  width: 70%;
}*/

.txt-main {
  color: #005ae6;
}

.icon-fb {
  color: white !important;
  background-color: #3b5998 !important;
}

.icon-ws {
  color: white !important;
  background-color: #25d366 !important;
}

button.instagram{
  color: white;
  background: #f09433;
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}