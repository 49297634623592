.main-container {
  border-radius: 0 0 10px 10px;
  background-color: #ffc800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}


.MuiFormControl-root .MuiFormHelperText-root {
  font-size: inherit !important;
}

.MuiFormHelperText-contained {
  font-size: inherit !important;

}

.wrapper {
  position: relative;
}

.buttonProgress {
  color: green;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

