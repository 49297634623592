p {
  margin: 0;
}

.main-container {
  border-radius: 0 0 10px 10px;
  background-color: #ffc800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.filter-container {
  width: calc(100% - 40px);
  padding: 8px;
  border-radius: 10px;
  background-color: white;
}