.avatar {
  box-shadow: 0 0 8px #848484;
}

.blue-back {
  background-color: dodgerblue !important;
}

div.avatar-expand {
  width: 70px;
  height: 70px;
}

.logout-pos {
  position: absolute;
  bottom: 4px;
  right: 4px;
}